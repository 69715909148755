/* src/components/GalleryPage.css */

.gallery-container {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .gallery-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(238, 236, 236, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 30px rgba(237, 233, 233, 0.725);
  }
  
  .gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
  