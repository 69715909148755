nav {
  background-color: black;
  font-family: Roboto;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 8%;
    padding-right: 8%; */
  padding: 5px 5%;
  color: #f1f1f1;
  z-index: 1000;
  position: sticky;
  top: 0;
}

nav .navlogo {
  width: 250px;
  /* height: 120px; */
}

.navlinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.navlinks li {
  color: #f1f1f1;
  font-size: 1.3rem;
  list-style: none;
  margin: 0 8px;
  cursor: pointer;
  animation: pulse 2s infinite;
}

.navlinks li:hover {
  transform: scale(1.15);
  /* list-style:; */
}

.demoBtn {
  background-color: black;
  font-size: 1rem;
  color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 15px;
  border: 0.1px solid rgb(135, 132, 132);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  animation: pulse 2s infinite;
}

.demoBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    #3498db 25%,
    #8e44ad 75%,
    transparent 100%
  );
  transition: left 0.5s ease;
  z-index: -1;
}

.demoBtn:hover {
  color: #f1f1f1;
  transform: scale(1.15);
  /* background-color: #f1f1f1; */
}

.demoBtn:hover:before {
  left: 100%;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #f1f1f1;
  margin: 3px 0;
  transition: 0.4s;
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: flex;
  }

  .nav.open .navlogo {
    display: none;
  }
  

  .navlinks {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .navlinks.open {
    display: flex;
  }

  .navlinks li {
    margin: 10px 0;
  }

  .menu-icon.open {
    position: absolute;
    top: 5%;
    right: 8%;
  }

  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  nav {
    padding: 5px 3%;
  }

  .navlinks li {
    margin: 0 5px;
  }
}

@media screen and (min-width: 1025px) {
  nav {
    padding: 5px 5%;
  }
}
