.heroServices {
  background-color: white;
  height: 80vh;
  background-image: url("../../assests/sliderimgs/slider4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 

}

.servicesintro{
    background-color: white;
    color: black;
    position: relative;
    margin: 2% 20%;
    padding: 30px;
    top:20%
}

.heroServices h1{
    font-size: 5rem;
    
}

.servicesgrid {
  padding: 50px;
  margin-top: 50px;
  display: flex;
  /* gap: 180px; */
  /* height: 100%; */
  background-color: white;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.services {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 1rem;
  max-width: 400px;
  padding: 10px;
  /* margin-bottom: 150px; */
}

.services img {
  width: 50px;
  height: 50px;
}

.servicecontent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.servicesname {
  font-size: 1.5rem;
  font-weight: 600;
}


/* mobile responsive */
@media (max-width: 768px){
  .servicesintro h1{
    font-size: 4rem;
  }
  .servicesintro p{
    font-size:10px;
  }
}

