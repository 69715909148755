.hero-container {
  overflow: hidden;
  position: relative;
  height: 90vh;
  width: 100vw;
}

.sections-wrapper {
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.section1 {
  /* display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem; */
  background-image: url(../assests/sliderimgs/slider1.jpg);
  background-position: center;
  background-size: cover;
}
.section1 .content{
  background-color: #7a757554;
  
}
.content {
  color: #fefae0;
  background-color: #7a757554;
  font-size: 80px;
  font-weight: 700;
  position: relative;
  max-width: 600px;
  top: 20%;
  left: 5%;
  padding: 20px;
}

.content p{
    font-size: 20px;
    font-weight: 500;
}
.section2 {
  background-image: url(../assests/sliderimgs/backend.jpg);
  background-position: center;
  background-size: cover;
}
.section3 {
  background-image: url(../assests/sliderimgs/slider3.jpg);
  background-position: center;
  background-size: cover;
}
.section5 {
  background-image: url(../assests/sliderimgs/slider5.jpg);
  background-position: center;
  background-size: cover;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 15px;
  font-size: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  z-index: 10;
  user-select: none;
}

.WebDomainbtn{
  padding: 10px 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  border: none;
  color: black;
}

.WebDomainbtn:hover{
  cursor: pointer;
  background-color: black;
  color: white;
  transition: 0.5s;
}

.left-button {
  left: 10px;
}

.right-button {
  right: 10px;
}


@media (max-width:768px) {
  .content{
    width:60%
  }

  .content h3{
    font-size: 30px;
    width: 100%;
  }
  .content p{
    /* font-size: 15px; */
    display: none;
  }


  .nav-button{
    width: 10px;
    background-color: transparent;
    padding: 5px;
  }
  .WebDomainbtn{
    width: 100px;
    padding: 10px;
  }
}