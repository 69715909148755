.web{
    /* background-color: red; */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 100px;
    gap: 20px;
}

.web ol{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.web p{
    max-width: 80%;
    font-size: 1.2rem;
}

.heading{
    font-size: 2rem;
    padding: 20px;
}

.headingWord{
    background-color: rgb(40, 40, 243);
    width: fit-content;
    padding: 10px;
}

@media (max-width:768px) {
    .heading{
        font-size: 1.3rem;
    }
    .web{
        padding: 20px;
    }
    .web h3{
        font-size: 20px;
        width: 100%;
    }
    .web p{
        font-size: 13px;
    }
    .web ol{
        padding: 0 30px;
        font-size: 13px;
    }
}