@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  background-color: black;
}
.homeContainer {
  /* background-color: red; */
  font-family: "Roboto", "sans-serif";
  color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.herosection {
  width: 100%;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.heroContent {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 2rem;
}

.heroimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(2px);
}

.heroimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroimg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
/* 
.homeContainer::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-image: url(../../assests/shubham-dhage-v0VjjYYFjOg-unsplash.jpg);
  background-size: cover;
  background-position: center;
  filter: blur(2px);
  z-index: -1;
} */

.homeHeroHeading {
  font-size: 2rem;
  margin-bottom: 1rem;
  padding-top: 70px;
  /* animation: fadeIn 2s ease-out; */
}

.homeheroDescription {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.6;
  font-size: 1.5rem;
  /* animation: slideUp 1s ease-out; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-90px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(80px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.line {
  width: 80%;
  padding: 0 10%;
  height: 2px;
  background-color: #f1f1f1;
  margin: 0;
  transition: 0.4s;
  opacity: 0.5;
}

.linesection {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
  padding-top: 2%;
}

/* vision container */

.visionContainer {
  background-color: black;
  color: #f1f1f1;
  /* height: 100vh; */
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 80%;
}

.visionHeading {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 35px 0;
  letter-spacing: 2px;
}

.visionDescription {
  font-size: 5rem;
  font-weight: 300;
  padding-bottom: 40px;
  /* animation: fadeUp 2s ease-in; */
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateX(-190px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.visionContent {
  display: flex;
  align-items: end;
  gap: 5rem;
  padding: 40px 0;
  justify-content: space-between;
  /* animation: slideUp 2s ease-out; */
}

.visionContent img {
  width: 500px;
  border-radius: 10%;
}

.visionContent p {
  font-size: 1.3rem;
  max-width: 500px;
  font-family: "Space Grotesk", sans-serif;
  line-height: 1.3;
  margin: 40px 0;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(190px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* product display section */
.productcontainer {
  width: 100%;
  margin: 0;
  background-color: #f1f1f1;
  color: black;
  font-family: "Space Grotesk", sans-serif;
}

.linesectionproduct {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
  padding-top: 2%;
}

.lineproduct {
  width: 80%;
  padding: 0 10%;
  height: 2px;
  background-color: black;
  margin: 0;
  transition: 0.4s;
  opacity: 0.5;
}
.productheading p {
  padding-left: 150px;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 35px 0;
  /* letter-spacing: 1px; */
  /* font-family: "Space Grotesk", sans-serif; */
}

.productslist {
  padding: 50px;
  display: flex;
  justify-content: space-evenly;
}

.productcontent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  padding: 10px;
}

.productcontent img {
  width: 200px;
}

.productname {
  font-size: 1.5rem;
  font-weight: 600;
}

/* services */

.servicescontainer {
  width: 100%;
  margin: 0;
  background-color: #f1f1f1;
  color: black;
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesheading p {
  padding-left: 150px;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 35px 0;
}

.serviceslist {
  padding: 50px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.services {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 1rem;
  max-width: 400px;
  padding: 10px;
}

.servicesNavBtn {
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  border: none;
  background-color: black;
  color: #f1f1f1;
  margin: 20px;
}

.servicesNavBtn:hover {
  background-color: #f1f1f1;
  color: black;
  border: 1px solid black;
  transition: 0.5s;
}

.services img {
  width: 50px;
  height: 50px;
}

.servicecontent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.servicesname {
  font-size: 1.5rem;
  font-weight: 600;
}

.contactUs {
  width: 100%;
  margin: 0;
  /* background-color: #f1f1f1; */
  color: #f1f1f1;
  font-family: "Space Grotesk", sans-serif;
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map {
  display: flex;
  gap: 6em;
  align-items: center;
}

.contactUsHeading p {
  padding-left: 150px;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 35px 0;
}

.contactform {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 2rem;
  padding: 30px 0;
}

.contactform div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.contactform label {
  font-size: 16px;
}
.contactform input {
  width: 500px;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-size: 1.5rem;
  padding: 0 10px;
}

.contactform textarea {
  width: 500px;
  /* height: 50px; */
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  padding: 0 10px;
}

.contactform button {
  width: 100px;
  height: 50px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: black;
  color: #f1f1f1;
}

.footer {
  background-color: white;
  color: black;
  padding: 2rem 1rem;
  margin-top: 2%;
  width: 100%;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo {
  margin-bottom: 1.5rem;
}

.footer-logo-container {
  display: flex;
  align-items: center;
}

.footer-logo-container img{
  width: 150px;
}

.footer-logo-text {
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 0.5rem;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-left: 2%;
}
.footer-section {
  font-family: "Space Grotesk", sans-serif;
}
.footer-section h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 0.5rem;
  font-family: "Roboto", "sans-serif";
}

.footer-section a {
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "sans-serif";
}

.footer-section a:hover {
  text-decoration: underline;
}

.add li {
  font-family: "Roboto";
}

@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
  }

  .footer-logo {
    margin-bottom: 0;
  }

  .footer-links {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* General styles for mobile */
@media (max-width: 768px) {
  .homeContainer {
    padding: 10px;
  }

  .herosectionHome {
    width: 100%;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin: 0;
    z-index: 11;
  }

  .homeHeroHeading h1 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 40px;
    /* margin-bottom: 150px; */
    text-align: center;
  }

  .homeheroDescription {
    max-width: 400px;
    margin-bottom: 250px;
    text-align: center;
    line-height: 1.2;
    font-size: 1.1rem;
    padding: 0 30px;
    font-family: "Space Grotesk", sans-serif;
  }

  .heroimg {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 80%;
    z-index: -1;
    filter: blur(2px);
  }

  .heroimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .visionContainer {
    padding: 10px;
  }
  .visionDescription p {
    font-size: 30px;
  }
  .visionHeading p {
    font-size: 20px;
  }

  .visionContent p {
    text-align: center;
    margin: 0;
  }

  .visionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .visionContent img {
    width: 100px;
    padding-bottom: 20px;
    margin: 0;
    /* margin-top: 10px; */
  }

  /* .visionContent{
.
 
   
  }
  .visionContent p {
    font-size: 14px;
    line-height: 1.4;
    width: 100%;
    text-align: center;
  }

  .visionContent img {
    width: 90%;
    margin-top: 10px;
  } */
  .productheading p {
    padding-left: 70px;
    font-size: 2.3rem;
    font-weight: 600;
    margin: 35px 0;
  }

  .productslist {
    flex-direction: column;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productcontent {
    margin-bottom: 20px;
  }

  .productcontent img {
    width: 40%;
    height: 100px;
  }
  .servicesheading p {
    padding-left: 8px;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 35px 0;
  }
  .serviceslist {
    flex-direction: column;
    padding: 10px;
  }

  .services {
    margin-bottom: 20px;
  }

  .servicesimg {
    width: 100%;
    height: auto;
  }

  .servicecontent h3 {
    font-size: 18px;
  }

  .servicecontent p {
    font-size: 14px;
  }
  .map{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 40px;
  }

 .mapframe{
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
 }
  .contactUsHeading{
    margin-right: 100px;
  }
  .contactform label {
    font-size: 16px;
  }

 
  .contactform input {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 10px;
  }

  .contactform textarea {
    width: 300px;
    /* height: 50px; */
    border: none;
    border-radius: 10px;
  }

  .contactform button {
    width: 100px;
    height: 50px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: black;
    color: #f1f1f1;
  }

  .contactUsHeading p {
    padding-left: 85px;
    font-size: 2.3rem;
    font-weight: 600;
    margin: 35px 0;
  }
}

@media only screen and (max-width: 600px) {
  .visionContainer {
    display: flex;

    /* height: 800px; */
    width: auto;
  }
  .visionContent p {
    font-size: 18px;
    width: 70%;
    text-align: center;
  }

  .visionContent img {
    width: 70%;
    height: 280px;
    /* margin-top: 60px;
    margin-left: 25px; */
  }
}
